.artistContainer {
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  padding: 3rem 0 3rem;
}
.artistContainer h1 {
  font-size: 6rem;
  padding: 3rem 0  4rem;
}
.artistWrap {
  width: 70%;
  max-width: 1100px;
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.8rem;
}

.artistItemImg {
  width: 19rem;
  height: 19rem;
  border-radius: 50%;
  border: 2px dashed #616161;
  margin: 0 auto;
  overflow: hidden;
}
.artistItemImg img {
  width: 100%;
}
.artistItem h2 {
  font-size: 3rem;
  padding: 2rem 0;
  text-transform:uppercase;
}
@media (max-width: 1100px) {
  .artistContainer h1{
    font-size: 4rem;
  }
  .artistWrap  {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .artistItemImg {
    width: 15rem;
    height: 15rem;
  }  
  .artistWrap {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 0;
  }
}