.aboutContainer {
  width: 100%;
  background-color: #000;
  text-align: center;
  padding: 7rem 0;
}

.aboutWrap {
  width: 70%;
  max-width: 800px;
  margin: 0 auto;
}

.aboutContainer h1 {
  padding: 20px;
  font-size: 8rem;
}

.aboutContainer p {
  padding: 20px;
  font-size: 3.5rem;
  opacity: 0.8;
}

.aboutContainer p span {
  opacity: 0.9;
}

@media (max-width: 1100px) {
  .aboutContainer h1 {
    font-size: 6rem;
  }
  
  .aboutContainer p {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .aboutContainer h1 {
    font-size: 6rem;
  }
  
  .aboutContainer p {
    font-size: 2.5rem;
  }

  .aboutWrap {
    width: 90%;
  }
}