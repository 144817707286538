.containerTickets {
  width: 100%;
  height: auto;
  margin-top: 3vh;
  background-color: #000000;
  opacity: 0.8;
  display: block; 
  padding: 4rem 1rem;
  color: #ffffff;
  text-align: center;
}

.ticketsToBuy {
  font-size: 8rem;
  letter-spacing: 1rem;
}

.ticketsChoise {
  font-size: 3.5rem;
  letter-spacing: 1rem;
  padding-top: 1rem;
}

.ticketBtn {
  text-align: center;
  background-color: #000000;
  font-size: 22px;
  border: 1px #ffffff  solid;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 1.5rem 1.75rem;
  height: 6rem;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: .4s;
  text-decoration: none;
  color: #ffffff;
}
.ticketBtn:hover {
  background-color: #000;
  color: #fff;
}
.ticketsContainer  {
  max-width: 1200px;
  width: 70%;
  padding: 70px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8rem;
}
.containerTickets p {
  font-size: 28px;
}


@media (max-width: 1100px) {
  .ticketsToBuy {
    font-size: 6rem;
    letter-spacing: 1rem;
  }
  
  .ticketsChoise {
    font-size: 3rem;
    letter-spacing: 1rem;
  }

  .ticketsContainer  {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .ticketsToBuy {
    letter-spacing: 0.5rem;
    font-size: 4rem;
  }
  
  .ticketsChoise {
    font-size: 3rem;
    letter-spacing: 0.5rem;
  }

  .ticketsContainer  {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    gap: 0;
  }
}