.containerGallery {
  width: 100%;
  height: auto;
  margin-top: 3vh;
  background-color: #000000;
  opacity: 0.8;
  display: block; 
  padding: 4rem 1rem;
  color: #ffffff;
  text-align: center;
}
.galleryMainText {
  font-size: 7rem;
  letter-spacing: 1rem;
}

.galleryChoise {
  font-size: 3.5rem;
  letter-spacing: 1rem;
  padding-top: 1rem;
}
.galleryBtn {
  text-align: center;
  background-color: #fff;
  font-size: 22px;
  border: 1px #000  solid;
  width: 80%;
  display: inline-block;
  box-sizing: border-box;
  padding: 1.5rem 1.75rem;
  height: 6rem;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: .4s;
  text-decoration: none;
  color: #000;
}
.galleryBtn:hover {
  background-color: #000;
  color: #fff;
}
.buttonsContainer  {
  max-width: 1200px;
  width: 70%;
  padding: 70px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.8rem;
}

/* ------- Photo page ---------- */
.wrapper {
  width: 100%;
  height: auto;
  background-color: #fff;
  opacity: 0.8;
  display: block; 
  border-top: 2px dashed #616161;
  padding: 4rem 1rem;
  color: #000;
  text-align: center;
}
.galleryText {
  font-size: 3.5rem;
  padding-bottom: 2rem;
}
.img-box {
  column-count: 4;
  column-gap: 2px;
  max-width: 90%;
  margin: 0 auto;
}
.img-box img {
  width: 100%;
  display: block;
  margin-bottom: 2px;
  border-radius: 3%;
}

/* ---------- Video page ------------ */

.video-box {
  max-width: 80%;
  margin: 0 auto;
}

.video {
  margin: 0 auto;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 1100px) {
  .img-box{
    column-count: 2;
    width: 95%;
  }
  .video-box {
    width: 90%;
  }
  .galleryMainText {
    font-size: 7rem;
    letter-spacing: 0.5rem;
  }
  .galleryChoise {
    font-size: 4rem;
    letter-spacing: 0.5rem;
    padding-top: 1rem;
  }
  .galleryBtn {
    width: 80%;
    font-size: 26px;
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .galleryMainText {
    font-size: 5rem;
    letter-spacing: 0.5rem;
  }
  .galleryChoise {
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    padding-top: 1rem;
  }
  .galleryBtn {
    width: 90%;
  }
  .buttonsContainer  {
    width: 90%;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 500px) {
  .galleryBtn {
    width: 100%;
  }
  .buttonsContainer  {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .img-box{
    column-count: 1;
    width: 95%;
  }
  .video-box {
    width: 98%;
  }
}