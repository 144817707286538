@font-face {
  font-family: "AgencyFBCyrillic";
  src: url("../fonts/agencyfbcyrillic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DrukWide";
  src: url("../fonts/Druk-Wide-Cy-Web-Medium-Regular.ttf");
  font-weight: normal;
  font-style: normal;
} 
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
body {
  background-color: #000;
  color: #ffffff;
  font-family: AgencyFBCyrillic;
  overflow-y: scroll;
}
a, ul, li {
  text-decoration: none;
}
@media (min-width: 2560px) {
  html {
    zoom: 1.3;
  }
} 
@media (min-width: 3860px) {
  html {
    zoom: 1.5;
  }
}
.errorMessage {
  text-align: center;
  font-size: 10rem;
  margin: 0 auto;
  padding-top: 20%;
}
.errorTooltip {
  font-size: 6rem;
  text-align: center;
}
.errorTooltip a {
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
  transition: .4s;
}
.errorTooltip a:hover{
  opacity: 1;
}
.copyrigtText {
  text-align: center;
  padding: 20px;
  font-size: 2rem;
  color: #616161;
}