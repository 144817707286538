.socialContainer {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  padding: 3rem 0 3rem;
}
.socialWrap {
  width: 70%;
  max-width: 1100px;
  margin: 0 auto;
  height: auto;
}
.social {
    margin: 2rem auto;
    display: inline-block;
  width: 40%; 
  align-items: center;
}
.socialWrap h1 {
  font-size: 6rem;
  padding-bottom: 25px;
}
.social h2 {
  font-size: 3rem;
}
.social p {
  font-size: 2.5rem;
  margin-top: 5px;
}
.social p a{
  color: #ffffff;
  opacity: 0.8;
  transition: .4s;
}
.social p a:hover{
  color: #9b9b9b;
  opacity: 1;
}
.socialImage {
  width: auto;
  height: 2.5vh;
  margin-right: 1rem;
}
@media (max-width: 1100px) {
  .socialWrap h1 {
    font-size: 5rem;
  }
}

@media (max-width: 768px) {
  .social {
    margin: 2rem auto;
    display: block;
    width: 60%;
  }
  .socialWrap h1 {
    font-size: 4.5rem;
  }
  .social p {
    font-size: 2.2rem;
  }
}