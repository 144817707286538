.faqContainer {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  padding: 7rem 0 7rem;
}
.faqWrap {
  width: 70%;
  max-width: 1100px;
  margin: 0 auto;
}
.faqWrap h1 {
  font-size: 6rem;
  padding-bottom: 15px;
}
.faqWrap h2 {
  font-size: 3.5rem;
  padding: 25px 0;
  width: 100%;
  text-align: left;
}
.faqWrap p {
  font-size: 3rem;
  padding: 0 0 25px 25px;
  opacity: 0.8;
  width: 100%;
  text-align: left;
}
.faqWrap a {
  text-decoration: none;
  color: #fff;
  animation-name: linkAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction:alternate;
  transition: .4s;
}
.firstBlock h2 {
  border-top: 2px dashed #616161;
}
.firstBlock p:last-child,
.secondBlock p:last-child,
.thirdBlock p:last-child{
  border-bottom: 2px dashed #616161;
}
@keyframes linkAnimation {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1100px) {
  .faqWrap {
    width: 80%;
  }
  .faqWrap h1 {
    font-size: 6rem;
  }
  .faqWrap h2 {
    font-size: 3.5rem;
  }
  
  .faqWrap p {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .faqWrap {
    width: 85%;
  }
  .faqWrap h1 {
    font-size: 5rem;
  }
  .faqWrap h2 {
    font-size: 3rem;
  }
  .faqWrap p {
    font-size: 2.2rem;
  }
}