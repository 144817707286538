.teamContainer {
  width: 100%;
  background-color: #000;
  color: #ffffff;
  text-align: center;
  padding: 3rem 0 3rem;
}
.teamContainer h1 {
  font-size: 6rem;
  padding: 3rem 0  4rem;
}
.teamWrap {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 1.8rem;
  grid-column-start: auto;
}

.teamItemImg {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 2px dashed #616161;
  margin: 0 auto;
  overflow: hidden;
}
.teamItemImg img {
  width: 100%;
}
.teamItem h2 {
  font-size: 4rem;
  padding-top: 1.5rem;
  text-transform:uppercase;
}
.teamItem h3 {
  font-size: 2rem;
  padding-bottom: 2rem;
  text-transform:uppercase;
}

@media (max-width: 1100px) {
  .teamContainer h1{
    font-size: 4rem;
  }
  .teamWrap  {
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .teamWrap {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    gap: 0;
  }
}