.nav-container {
  background-color: #6b6b6b;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 300;
  height: 4vh;
}
.nav-container nav {
  width: 70%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 8px;
  height: 100%;
}
nav .desktop-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  height: 100%;
}

nav .desktop-nav li a {
  color: #ffffff;
  font-size: 22px;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 400ms;
}

nav .desktop-nav li a img {
  width: auto;
  height: 30%;
}

nav .desktop-nav li a:hover {
  opacity: 1;
}
.headerLogo {
  background: url('../assets/logo.png');
  display: block;
  background-position: center;
  height: 4vh;
  background-size: 2.5vh;
  width: 4vh;
  background-repeat: no-repeat;
}
.headerPerson {
  background: url('../assets/oerson_icon.png');
  display: block;
  background-position: center;
  height: 4vh;
  background-size: 3vh;
  width: 4vh;
  background-repeat: no-repeat;
}

.mobile-nav {
  display: none;
}

@media (max-width: 1100px) {
  .nav-container nav {
    width: 100%;
    max-width: 900px;
    padding: 0 32px;
  }
}

@media (max-width: 768px) {
  .nav-container .desktop-nav {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0vh;
    background-color: #000;
    justify-content: start;
    overflow: hidden;
    z-index: -1;
  }

  .nav-container.active .desktop-nav {
    height: 100vh;
  }

  .nav-container .desktop-nav li {
    width: 100%;
    padding: 0 32px;
  }
  .nav-container .desktop-nav li:first-child {
    margin-top: 120px;
  }

  .nav-container .desktop-nav .headerPerson,
  .nav-container .desktop-nav .headerLogo{
    display: none;
  }

  .nav-container .desktop-nav li a {
    padding: 15px 0;
    display: inline-block;
    border-bottom: 1px solid #000000;
    width: 100%;
    font-size: 24px;
  }
  nav .mobile-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
  }
  nav .menu-icon-container {
    width: 20px;
    height: 4vh;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  nav .menu-icon {
    position: relative;
    width: 100%;
  }

  nav .menu-icon .line-1,
  nav .menu-icon .line-2 {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
  nav .menu-icon .line-1 {
    top: -4px;
  }
  nav .menu-icon .line-2 {
    top: 4px;
  }
  nav .menu-icon-container.active .menu-icon .line-1 {
    top: 0;
    transform: rotate(45deg);
  }
  nav .menu-icon-container.active .menu-icon .line-2 {
    top: 0;
    transform: rotate(-45deg);
  }
}